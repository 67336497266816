.event-container {
  text-align: center;
  padding: 20px;
  /* background: #f5f5f5; */
}

.event-description {
  font-size: 18px;
  color: #333;
}

.event-section {
  background: white;
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 24px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

/* Animations */
.fade-in {
  animation: fadeIn 1s ease-in;
}

.slide-in {
  animation: slideIn 1s ease-in-out;
}

.zoom-in {
  animation: zoomIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes zoomIn {
  from { transform: scale(0.9); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.button-end {
  width: 30%;
  padding: 20px;
  font-size: 18px;
  border-radius: 8px;
  text-align: center;
  background-color: #f68632; 
  transition: all 0.3s ease;
  color: white;
}

@media (max-width: 768px) {
  .button-end {
    width: 100%;
    padding: 15px; 
    font-size: 16px; 
  }
}

@media (max-width: 480px) {
  .button-end {
    width: 100%;
    padding: 12px; 
    font-size: 14px; 
  }
}


.button-end a {
  display: block;
  text-decoration: none;
  color: inherit;
}

.info-text {
  text-align: center;
  font-size: 1.125rem; /* Equivalent to text-lg */
  font-weight: 600;    /* Equivalent to font-semibold */
  margin-top: 1rem;    /* Equivalent to mt-4 */
}

.info-link {
  color: #3b82f6;  /* Equivalent to text-blue-500 */
  transition: color 0.2s ease-in-out;
}

.info-link:hover {
  color: #1d4ed8;  /* Equivalent to text-blue-700 */
}